import React from "react"

import BarStep from "./partials/bar-step"
import AddCarKms from "../car/add-car-kms"
import Layout from "../../components/layout";

const AddCarKmsPage = () => {
  return (
    <div>
      <Layout>
        <BarStep selected="step1" />
        <AddCarKms navigateTo='/profile/add-car-last-service' />
      </Layout>
    </div>
  );
};

export default AddCarKmsPage
